<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-nav tabs>
            <b-nav-item to="/occurrences/external">Occurrences External</b-nav-item>
            <b-nav-item v-if="config.editable" active to="/occurrences/container">Occurrences External (Container)</b-nav-item>
            <b-nav-item v-if="config.editable" to="/occurrences/internal">Occurrences Internal</b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.dict.occurrence.add')" class="float-right" v-b-modal.modal-new-occurrence>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new occurrence
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-occurrence" title="New occurrence" ok-title="Save" @ok="saveOccurrence" size="lg">
            <OccurrenceNew :type="'containeroccurrence'" ref="newOccurrenceForm" />
          </b-modal>
          <LegamDataTables v-if="config.editable" v-bind="$data" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide v-else v-bind="$data" :additionalData="tableAdditionalData" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }

  * >>> tr.orange td {
    color: #ff6600;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import tdColSafeHTML from '@/components/tdColSafeHTML.vue'
  import tdColAction from '@/components/tdColAction.vue'
  import tdColUUID from '@/components/tdColUUID.vue'
  import OccurrenceNew from '@/components/OccurrenceNew.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  // import FilterExternaEvidenceComponentDatatables from '@/components/FilterExternaEvidenceComponentDatatables.vue'
  import router from '@/router'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'OccurrenceListExternalContainer',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },
          { title: 'Form', field: 'surfaceForm', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Edition', field: 'editionSigleLegam', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
            let retVal = []
            let prefix = row.isReconstructed ? '*' : ''
            if (row.editionId != null){
              retVal.push({
                text: `${prefix}${row.editionSigleLegam}`,
                icon: 'book',
                id: row.editionId,
                to: {name: 'EditionDetails', params: {slug: '-', id: row.editionId}}
              })
            }
            return retVal
          }},
          { title: this.$t('Position'), field: 'position', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Dictionary', field: 'acronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Language', field: 'languageCode', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Lemma', field: 'lemmaForm', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
            let retVal = []
            let prefix = row.isReconstructed ? '*' : ''
            if (row.lemmaId != null){
              retVal.push({
                text: `${prefix}${row.lemmaForm}`,
                icon: 'book',
                id: row.lemmaId,
                to: {name: 'Lemma', params: {id: row.lemmaId}}
              })
            }
            return retVal
          }},
          { title: 'Article', field: 'rootLemmaForm', sortable: true, tdComp: tdColAction, searchable: true, transformData: function(row){
            let retVal = []
            if (row.articleId != null){
              retVal.push({
                text: row.rootLemmaForm,
                icon: 'book',
                id: row.id,
                to: {name: 'Article', params: {id: row.articleId}}
              })
            }
            return retVal
          }},
          { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              let retVal = [
                {text: null, icon: 'list-ul', id: row.id, to: {name: 'OccurrenceDetails', params: {id: row.id}}}
              ]
              return retVal
            }
          }
        ],
        url: config.editable? '/occurrences/external/container/datatables' : '/occurrences-external-container/datatables',
        key: 1,
        searchableByColumn: true,
        config: config
      }
    },
    components: {
      LegamDataTables,
      LegamDataTablesClientSide,
      OccurrenceNew
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
      tableAdditionalData(){
        return {
          'dictionaries': this.userSettings.dictionaries
        }
      }
    },
    methods: {
      saveOccurrence(bvModalEvt){
        bvModalEvt.preventDefault()
        if (this.$refs.newOccurrenceForm.validate()){
          this.$store.dispatch('createOccurrence', HelpStore.item).then((response) => {
            router.push(`/occurrence/${response.data.id}`)
          })
        }
      }
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
